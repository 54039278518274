import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import { Context } from './Context';

var decode = require('unescape');


export default class IndexPage extends React.Component {

  render() {
    const { posts, title } = this.props

    return (
      <Context.Consumer>
        {({ lang }) => (
          <div className="row post-list">
            {posts.map(({ node: post }) => (
              (post.wordpress_site===lang && (
                <div className="col-lg-6 col-md-12 col-sm-6" key={post.id}>
                    <div className="type-post">
                        <div className="entry-cover">
                            <div className="post-meta">
                                {/* <span className="byline">by <Link to={lang + "/" + decodeURI(post.slug)} title="Indesign">{post.author.name}</Link></span> */}
                                <span className="post-date"><Link to={"/" + lang + "/" + decodeURI(post.slug)}>{post.date}</Link></span>
                            </div>
                            <div className="post-image">
                              <Link to={"/" + lang + "/" + decodeURI(post.slug)}><img src={ post.better_featured_image ?  post.better_featured_image.source_url : "https://placehold.it/477x500"} alt="Slide"/></Link>
                            </div>
                        </div>
                        <div className="entry-content">
                            <div className="entry-header">
                                <span className="post-category">
                                  <Link to={"/" + lang + "/" + post.slug} title={post.slug}>
                                    {post.categories.map((category,index) => (
                                      <span className="post-cat-span mr-1 ml-1" key={index}>{decode(category.name)}</span>
                                    ))}
                                  </Link>
                                </span>
                                <h3 className="entry-title"><Link to={"/" + lang + "/" + decodeURI(post.slug) + "/"} title={post.title}><span dangerouslySetInnerHTML={{ __html: post.title }}></span></Link></h3>
                            </div>
                            <div  dangerouslySetInnerHTML={{
                                __html: post.excerpt.replace(/<p className="link-more.*/, '').replace(/<a className="btn-filled btn.*/,''),
                            }}></div>
                            <Link to={"/" + lang + "/" + decodeURI(post.slug) + "/"} title="Read More">Read More</Link>
                        </div>
                    </div>
                </div>
              ))
            ))}
          </div>
        )}
      </Context.Consumer>
    )
  }
}

IndexPage.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export const pageQuery = graphql`
  fragment PostListField on wordpress__POST {
    id
    title
    excerpt
     better_featured_image {
      source_url
    }
     categories {
      name
    }
    author {
      name
      slug
      avatar_urls {
        wordpress_48
      }
    }
    date(formatString: "MMMM DD, YYYY")
    slug
    wordpress_site
  }
`
